<template>
  <div class="email-app-details">
    <!-- Email Header -->
    <div class="email-detail-header py-1 mb-1" style="background: #1a2135">
      <!-- Header: Left -->
      <div class="email-header-left d-flex align-items-center">
        <span class="go-back mr-1">
          <feather-icon
            :icon="
              $store.state.appConfig.isRTL
                ? 'ChevronLeftIcon'
                : 'ChevronRightIcon'
            "
            size="20"
            class="align-bottom mx-1"
            @click="$emit('close-email-view')"
          />
        </span>
        <div
          class="w-100 px-1 d-flex justify-content-between align-items-center"
        >
          <div>
            <h4 class="email-subject mb-0">
              {{ emailViewData.title }}
            </h4>
            <h6
              class="email-subject mb-0"
              style="padding-top: 5px; font-size: 12px"
            >
              {{ $t("Pages.Ticket.Title.ticket_id") }}: {{ emailViewData.uuid }}
            </h6>
          </div>
          <div class="email-header-right ml-2 pl-1">
            <p
              class="font-weight-bolder align-text-top"
              style="color: white; margin-bottom: 0"
            >
              {{ $t("Pages.Ticket.Label.Department") }}:
              {{
                department[
                  department.findIndex(
                    (e) => e.value == emailViewData.department
                  )
                ].name
              }}
            </p>
          </div>
        </div>
      </div>

      <!-- Header: Right -->
    </div>

    <!-- Email Details -->
    <!-- <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="email-scroll-area scroll-area px-0 mx-0 w-100"
    > -->

    <!-- Label Row -->
    <!-- <b-row>
        <b-col cols="12">
          <div class="email-label">
            <b-badge
              v-for="(label) in emailViewData.labels"
              :key="label"
              pill
              class="text-capitalize mr-50"
              :variant="`light-${resolveLabelColor(label)}`"
            >
              {{ label }}
            </b-badge>
          </div>
        </b-col>
      </b-row> -->

    <!-- Action: Show Earlier Message -->

    <b-row>
      <b-col cols="12">
        <ticket-message-card :message="emailViewData" />
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" v-if="emailViewData.replies.length > 0">
        <ticket-message-card
          v-for="replie in emailViewData.replies"
          :key="replie.id"
          :message="replie"
          :department="department"
          :emailViewData="emailViewData"
        />
      </b-col>
    </b-row>

    <!-- Email Actions: Reply or Forward -->
    <b-row
      v-if="!emailViewData.resolved_at && !emailViewData.closed_at"
      class="mt-1"
    >
      <b-col cols="12" class="mb-3">
        <b-card>
          <b-card-header>
            <h4>{{ $t("Pages.Ticket.Title.submit_answer") }}:</h4>
            <p>
              {{
                $t("Pages.Ticket.Title.use_form")
              }}
            </p>
            <b-alert class="p-1" variant="warning" show>
              <h4>
                {{
                  $t(
                    "Pages.Ticket.Title.pay_attention"
                  )
                }}:
              </h4>
              <ul>
                <li>
                  {{
                    $t(
                      "Pages.Ticket.Title.up_to_hours"
                    )
                  }}
                </li>
                <li>
                  {{
                    $t(
                      "Pages.Ticket.Title.resp_rime"
                    )
                  }}
                </li>
                <li>
                  {{
                    $t(
                      "Pages.Ticket.Title.reviewed_by_experts"
                    )
                  }}
                </li>
              </ul>
            </b-alert>
          </b-card-header>
          <div class="message-editor">
            <b-form-textarea
              id="quil-content"
              v-model="composeData.content"
              rows="11"
              style="resize: none"
            ></b-form-textarea>
          </div>
          <div class="d-flex justify-content-end align-items-center mt-1">
            <label for="myInputFile1" class="mx-2">
              <feather-icon
                icon="PaperclipIcon"
                size="17"
                class="ml-2 cursor-pointer"
              />
              <span v-if="composeData.file" class="px-1">{{
                $t("pages.Ticket.Title.selected_file")
              }}</span>
              <input
                type="file"
                @change="selectFile"
                id="myInputFile1"
                style="display: none"
              />
            </label>
            <b-button split variant="primary" right @click="send">
              {{ $t("Pages.Ticket.Title.submit_answer") }}
            </b-button>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <!-- </vue-perfect-scrollbar> -->
  </div>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
  BRow,
  BCol,
  BBadge,
  BCard,
  BLink,
  BButton,
  BAlert,
  BFormTextarea,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import TicketMessageCard from "./TicketMessageCard.vue";
import axios from "axios";

export default {
  components: {
    // BSV
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BBadge,
    BCard,
    BLink,
    BButton,
    BAlert,
    BFormTextarea,
    // 3rd Party
    VuePerfectScrollbar,
    // SFC
    TicketMessageCard,
  },
  props: {
    emailViewData: {
      required: true,
    },
    department: {
      required: true,
    },
  },
  data() {
    return {
      editorOption: {
        modules: {
          toolbar: "#quill-toolbar",
        },
        placeholder: this.$t("Base.Placeholder.msg_txt"),
      },
      perfectScrollbarSettings: { maxScrollbarLength: 150 },
      composeData: { file: null, content: "" },
      token_upload: null,
      upload_response: null,
      sending: false,
    };
  },
  methods: {
    async send() {
      if (this.composeData.file) {
        this.sendEmailWithFile();
      } else {
        this.sendEmail();
      }
    },
    async sendEmailWithFile() {
      if (!this.sending) {
        if (this.composeData.content != "") {
          this.sending = true;
          this.$http.get("/api/support/token-upload").then((response) => {
            this.token_upload = response.data.token;
            let data = new FormData();
            data.append("api_key", this.token_upload);
            data.append("file", this.composeData.file);
            axios
              .post("https://storage.master.tube/process.php", data)
              .then((response) => {
                this.upload_response = response.data.data.data;
                let appendData = new FormData();
                appendData.append("topic_id", this.emailViewData.id);
                appendData.append("content", this.composeData.content);
                appendData.append(
                  "attachment",
                  JSON.stringify(this.upload_response)
                );
                this.$http
                  .post("/api/support/reply", appendData)
                  .then((response) => {
                    this.sending = true;
                    this.$swal({
                      title: this.$t("Base.Alert.Successful"),
                      text: this.$t(
                        "Base.Alert.success_ans_submit"
                      ),
                      icon: "success",
                      confirmButtonText: this.$t(
                        "Base.Alert.Understand"
                      ),
                    }).then((ok) => {
                      location.reload();
                    });
                  });
              });
          });
        } else {
          this.makeToast(
            this.$t("Base.Alert.Error"),
            this.$t(
              "Base.Alert.fill_desc_ticket"
            ),
            "danger"
          );
        }
      }
    },
    sendEmail() {
      if (!this.sending) {
        this.sending = true;
        let data = new FormData();
        data.append("topic_id", this.emailViewData.id);
        data.append("content", this.composeData.content);
        this.$http.post("/api/support/reply", data).then((response) => {
          this.sending = true;
          this.$swal({
            title: this.$t("Base.Alert.Successful"),
            text: this.$t(
              "Base.Alert.success_ans_submit"
            ),
            icon: "success",
            confirmButtonText: this.$t("Base.Alert.Understand"),
          }).then((ok) => {
            location.reload();
          });
        });
      }
    },
    selectFile(e) {
      if (e.target.files.length) {
        this.composeData.file = e.target.files[0];
      } else {
        this.composeData.file = null;
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
</style>

<style lang="scss" scoped>
form ::v-deep {
  // Mail To vue-select style
  .v-select {
    .vs__dropdown-toggle {
      border: 0;
      box-shadow: none;
    }
    .vs__open-indicator {
      display: none;
    }
  }

  // Quill Editor Style
  .quill-editor {
    .ql-container.ql-snow {
      border-bottom: 0 !important;
    }
  }
}
</style>
<!-- <style lang="scss">
.scroll-area {
  width: 100%;
  height: 700px;
}
</style>  -->
