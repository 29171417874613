<template>
  <div style="height: inherit">
    <b-row>
      <b-col v-if="showFaqLink" lg="3" md="12" class="mx-0">
        <div class="sidebar-detached sidebar-left w-100">
          <div class="sidebar">
            <div class="sidebar-shop" :class="{ show: mqShallShowLeftSidebar }">
              <b-card class="faq-area">
                <div class="faq-section">
                  <feather-icon icon="MessageSquareIcon" class="faq-icon" />
                  <span>
                    {{ $t("Pages.Ticket.Title.before_send_ticket") }}</span
                  >
                  <p>
                    {{ $t("Pages.Ticket.Title.please_page") }}
                    <span> {{ $t("Pages.Ticket.Title.frq_q") }}</span>
                    {{ $t("Pages.Ticket.Title.check_out") }}
                  </p>
                  <b-button :to="{ name: 'support' }" variant="primary">
                    {{ $t("Pages.Ticket.Title.frq_q") }}
                  </b-button>
                </div>
              </b-card>

              <!-- Categories -->
              <!-- <b-card>
                <div class="product-categories">
                  <h6 class="filter-title mt-1">
                    {{ $t("Pages.Ticket.Title.Show Tickets") }}:
                  </h6>
                  <b-form-radio-group
                    v-model="selectedStatus"
                    class="categories-radio-group mt-2"
                    stacked
                    :options="status"
                    value-field="value"
                    text-field="name"
                    @change="getListTickets"
                  />
                </div>
              </b-card> -->
            </div>
          </div>

          <div
            class="body-content-overlay"
            :class="{ show: mqShallShowLeftSidebar }"
          />
        </div>
      </b-col>
      <b-col lg="9" md="12" class="mx-0">
        <!-- Overlay -->
        <div class="body-content-overlay" />
        <!-- Searchbar -->
        <div class="ecommerce-searchbar mt-1" v-if="!showEmailDetails">
          <b-row class="d-none d-lg-block">
            <b-col cols="12" class="">
              <b-input-group class="input-group-merge">
                <b-form-input
                  @keyup="getListTickets"
                  v-model="filter"
                  :placeholder="$t('Base.Placeholder.do_search')"
                  class="search-product"
                />
                <b-input-group-append is-text>
                  <feather-icon icon="SearchIcon" class="text-muted" />
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>

          <b-card class="faq-area d-lg-none">
            <div class="faq-section">
              <feather-icon icon="MessageSquareIcon" class="faq-icon" />
              <span> {{ $t("Pages.Ticket.Title.before_send_ticket") }}</span>
              <p>
                {{ $t("Pages.Ticket.Title.please_page") }}
                <span> {{ $t("Pages.Ticket.Title.frq_q") }}</span>
                {{ $t("Pages.Ticket.Title.check_out") }}
              </p>
              <b-button href="https://master.tube/faq/" variant="primary">
                {{ $t("Pages.Ticket.Title.frq_q") }}
              </b-button>
            </div>
          </b-card>
          <!-- <b-card class="d-lg-none">
            <div class="product-categories">
              <h6 class="filter-title mt-1">
                {{ $t("Pages.Ticket.Title.Show Tickets") }}:
              </h6>
              <b-form-radio-group
                v-model="selectedStatus"
                class="categories-radio-group mt-2"
                stacked
                :options="status"
                value-field="value"
                text-field="name"
                @change="getListTickets"
              />
            </div>
          </b-card> -->

          <b-row class="d-lg-none">
            <b-col cols="12">
              <b-input-group class="input-group-merge">
                <b-form-input
                  @keyup="getListTickets"
                  v-model="filter"
                  :placeholder="$t('Base.Placeholder.search_product')"
                  class="search-product"
                />
                <b-input-group-append is-text>
                  <feather-icon icon="SearchIcon" class="text-muted" />
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>
        </div>

        <b-col cols="12" class="card-body">
          <ul class="email-media-list px-0" v-if="!showEmailDetails">
            <b-media
              v-for="email in emails"
              :key="email.id"
              tag="li"
              no-body
              class="card mail-read px-1 py-1 mb-2 d-flex flex-column flex-lg-row"
              @click="updateEmailViewData(email)"
            >
              <b-media-aside
                class="media-left mr-50 my-auto d-none d-lg-block"
                style="align-self: center"
              >
                <b-avatar
                  class="avatar"
                  size="40"
                  variant="primary"
                  :src="email.creator.avatar"
                />
              </b-media-aside>
              <b-media-aside
                class="d-lg-none text-center d-flex justify-content-center align-items-center mx-auto mt-1"
                style="align-self: center"
              >
                <b-avatar
                  class="avatar"
                  size="40"
                  variant="primary"
                  :src="email.creator.avatar"
                />
              </b-media-aside>

              <b-media-body
                class="my-auto d-flex justify-content-lg-between text-md-left text-center d-lg-block mx-auto"
              >
                <div
                  class="mail-details d-flex justify-content-between align-items-center flex-column flex-lg-row mt-2 mt-lg-0 mx-auto"
                >
                  <div class="mail-items my-auto overflow-hidden">
                    <h5 class="mb-25">
                      <span>{{ email.title }}</span>
                    </h5>
                    <span
                      v-if="email.replies.length == 0"
                      class="text-truncate mail-message pt-1 w-75 overflow-hidden mb-0"
                      v-html="email.content.slice(0, 100)"
                    ></span>
                    <span
                      v-else
                      class="text-truncate mail-message pt-1 w-75 overflow-hidden mb-0"
                      v-html="
                        email.replies[email.replies.length - 1].content.slice(
                          0,
                          100
                        )
                      "
                    ></span>
                  </div>
                  <div
                    class="mail-meta-item d-flex justify-content-end align-items-center mt-lg-0 mt-1"
                  >
                    <div>
                      <feather-icon
                        v-if="email.attachments.length"
                        icon="PaperclipIcon"
                      />
                      <div
                        class="d-flex justify-content-center align-items-center flex-column"
                      >
                        <p class="mail-date font-weight-bold">
                          {{ MyDate.getFormatDateTime(email.updated_at) }}
                        </p>
                        <b-button
                          variant="warning"
                          v-if="email.reply_count == 0"
                          >{{ $t("Pages.Ticket.Title.wait_answer") }}</b-button
                        >
                        <b-button
                          variant="info"
                          v-else-if="
                            email.reply_count != 0 && email.resolved_at
                          "
                          >{{ $t("Pages.Ticket.Title.Solved") }}</b-button
                        >
                        <b-button
                          variant="danger"
                          v-else-if="email.reply_count != 0 && email.closed_at"
                          >{{ $t("Pages.Ticket.Title.Closed") }}</b-button
                        >
                        <b-button
                          variant="success"
                          v-else-if="
                            email.reply_count > 0 &&
                            email.replies[email.replies.length - 1]
                              .replier_id != email.creator.id
                          "
                          >{{ $t("Pages.Ticket.Title.Answered") }}</b-button
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <!-- <div class="mail-message">
                <p class="text-truncate mb-0">
                  <span class="text-truncate" v-html="email.content.slice(0, 200)"></span>
                </p>
              </div> -->
              </b-media-body>
            </b-media>
          </ul>
          <ticket-view
            v-else
            class="show"
            :email-view-data="emailViewData"
            @close-email-view="closeShowTicket"
            @move-email-to-folder="() => {}"
            @toggle-email-starred="() => {}"
            @update-email-label="() => {}"
            @mark-email-unread="() => {}"
            :department="department"
          />
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BCard,
  BButton,
  BAvatar,
  BMedia,
  BMediaAside,
  BMediaBody,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import MyDate from "@core/components/date/MyDate.vue";
import TicketView from "./TicketView.vue";
import { userInfoDao } from "@/daos/userInfo/userInfoDao";
const yotubeAccounts = process.env.VUE_APP_YOUTUBE_ACCOUNTS;

export default {
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BCard,
    BButton,
    BAvatar,
    BMedia,
    BMediaAside,
    BMediaBody,
    TicketView,
  },
  data() {
    return {
      MyDate,
      emails: [],
      emailsMeta: { inbox: 3, draft: 4, spam: 2 },
      perfectScrollbarSettings: {},
      showEmailDetails: false,
      emailViewData: [],
      shallShowEmailComposeModal: false,
      mqShallShowLeftSidebar: false,
      department: [],
      status: [],
      filter: "",
      selectedStatus: 1,
      userEmail: null,
    };
  },
  created(){
    this.handleGetUserInfo();
  },
  mounted() {
    this.getListDepartment();
    this.getListStatus();
    this.getListTickets();
  },
  watch: {
    $route(to, from) {
      if (to.query != from.query && to.query.uuid) {
        if (this.emails.findIndex((e) => e.uuid == to.query.uuid)) {
          this.emailViewData =
            this.emails[this.emails.findIndex((e) => e.uuid == to.query.uuid)];
          this.showEmailDetails = true;
        } else {
          //this.$router.push({ name: 'ticket'});
        }
      }
    },
  },
  computed:{
    showFaqLink(){
      const youtubeAccountsArray = yotubeAccounts.split(",");
      if (youtubeAccountsArray.includes(this.userEmail)) {
        return false;
      }
      return true
    }
  },
  methods: {
    getListTickets() {
      this.$http
        .get(`/api/support/list?s=${this.filter}&status=${this.selectedStatus}`)
        .then((response) => {
          this.emails = response.data;
          if (this.$route.query.uuid) {
            if (this.emails.find((e) => e.uuid == this.$route.query.uuid)) {
              this.emailViewData =
                this.emails[
                  this.emails.findIndex((e) => e.uuid == this.$route.query.uuid)
                ];
              this.showEmailDetails = true;
            } else {
              this.$router.push({ name: "ticket" });
            }
          }
        });
    },
    updateEmailViewData(email) {
      this.$router.push({
        name: "ticket",
        query: { uuid: email.uuid },
      });
      this.showEmailDetails = true;
      this.emailViewData = email;
    },
    getListDepartment() {
      this.$http.get("/api/support/department").then((response) => {
        this.department = response.data;
      });
    },
    closeShowTicket() {
      if (this.$route.query.uuid) {
        this.$router.push({ name: "ticket" });
      }
      this.showEmailDetails = false;
      this.emailViewData = null;
    },
    getListStatus() {
      this.$http.get("/api/support/status").then((response) => {
        this.status = response.data;
      });
    },
    async handleGetUserInfo() {
      const userInfo = await userInfoDao.getUserInfo();
      this.userEmail = userInfo.email;
    },
  },
};
</script>

<style lang="scss">
//@import "~@core/scss/base/pages/app-ecommerce.scss";
@import "~@core/scss/base/pages/app-email.scss";
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}

.item-img a {
  width: 100% !important;
}

.categories-radio-group,
.brands-radio-group,
.price-range-defined-radio-group {
  ::v-deep > .custom-control {
    margin-bottom: 0.75rem;
  }
}

.pos {
  top: 0px !important;
  right: 0px !important;
  left: 0;
}
</style>

<style lang="scss" scoped>
.scroll-area {
  width: 100%;
  height: 700px;
}
[dir] .email-application .content-area-wrapper {
  border: none !important;
  border-radius: 0 !important;
}

[dir="rtl"] .email-application .content-area-wrapper .content-right {
  border-right: none !important;
  margin-right: 2rem !important;
  border-radius: 0.428rem !important;
}

[dir]
  .email-application
  .content-area-wrapper
  .email-user-list
  .email-media-list
  li
  .mail-details {
  margin-bottom: 0 !important;
}

.email-application .content-area-wrapper .sidebar .email-app-sidebar {
  height: fit-content !important;
}

.sidebar {
  position: relative !important;
  width: 100% !important;
  height: fit-content !important;
}

.sidebar-left {
  height: fit-content !important;
}

[dir="rtl"]
  .email-application
  .content-area-wrapper
  .sidebar
  .email-app-sidebar {
  border-radius: 0.428rem !important;
}

[dir="rtl"]
  .email-application
  .content-area-wrapper
  .content-right
  .app-fixed-search {
  border-top-right-radius: 0.428rem !important;
  border-top-left-radius: 0.428rem !important;
}

[dir]
  .dark-layout
  .email-application
  .content-area-wrapper
  .email-app-list
  .email-user-list
  .mail-read {
  margin: 1rem !important;
  border-radius: 0.428rem !important;
}

[dir]
  .email-application
  .content-area-wrapper
  .email-user-list
  .email-media-list
  li
  .avatar {
  margin-bottom: 0 !important;
}

.email-application
  .content-area-wrapper
  .email-user-list
  .email-media-list
  li
  .mail-details {
  align-items: center !important;
}

.ticket-status {
  margin-bottom: 0 !important;
  border-radius: 0.428rem !important;
  width: 100% !important;
  padding: 0.5rem 1rem !important;
  font-size: 0.857rem !important;
  color: #000000 !important;
  text-align: center !important;
}

.email-app-details {
  width: fit-content !important;
}

.ticket-open {
  background-color: #fffacd !important;
}

.ticket-answered {
  background-color: #90ee90 !important;
}

.ticket-closed {
  background-color: #a9a9a9 !important;
}

.ticket-waiting {
  background-color: #add8e6 !important;
}

.user-subscription {
  font-size: 0.857rem !important;
  margin-top: 0.5rem !important;
  display: block !important;
}

[dir] .dark-layout .email-application .content-area-wrapper .email-app-details {
  background-color: transparent !important;
  border-radius: 0.428rem !important;
}

[dir]
  .email-application
  .email-app-details
  .email-scroll-area
  .email-detail-head {
  border-bottom: none !important;
}

.email-application .email-app-details {
  width: fit-content !important;
}

[dir] .dark-layout .card {
  background-color: #242b3d !important;
}

.dark-layout .text-body {
  font-size: 0.857rem !important;
}

h6.email-subject {
  margin-top: 0.25rem !important;
  font-size: 0.857rem !important;
}

[dir] .email-application .content-area-wrapper .go-back {
  transform: rotate(180deg) !important;
}

.email-header-right p {
  font-size: 0.857rem !important;
  margin-bottom: 0 !important;
  margin-top: 0.25rem !important;
  color: #d0d2d6 !important;
}

@media (max-width: 991.98px) {
  [dir="rtl"] .email-application .content-area-wrapper .content-right {
    margin-right: 0 !important;
  }
}

@media (max-width: 575.98px) {
  [dir]
    .dark-layout
    .email-application
    .content-area-wrapper
    .email-app-list
    .email-user-list
    .mail-read {
    display: block !important;
  }

  .email-application
    .content-area-wrapper
    .email-user-list
    .email-media-list
    li.media
    .media-left {
    width: 100% !important;
    display: block !important;
    text-align: center !important;
    margin-bottom: 20px !important;
  }

  .email-application
    .content-area-wrapper
    .email-user-list
    .email-media-list
    li.media
    .media-body {
    display: block !important;
    width: 100% !important;
  }

  .email-application
    .content-area-wrapper
    .email-user-list
    .email-media-list
    li
    .mail-details
    .mail-items {
    display: block !important;
    width: 100% !important;
    text-align: center !important;
  }

  .email-application
    .content-area-wrapper
    .email-user-list
    .email-media-list
    li
    .mail-details
    .mail-meta-item {
    position: unset !important;
    display: block !important;
    width: 100% !important;
    margin-top: 20px !important;
  }

  .ticket-status {
    width: fit-content !important;
    margin: 0 auto !important;
  }

  .email-scroll-area .card {
    width: 100% !important;
  }

  [dir]
    .email-application
    .content-area-wrapper
    .content-right
    .email-app-details
    .email-detail-header {
    display: block !important;
    height: auto !important;
  }
}

.email-media-list .media,
ul.email-media-list li.media {
  border-radius: 0.428rem !important;
}

[dir] .card-body.col-12 {
  padding: 1rem 0 !important;
}

.ecommerce-application .sidebar-shop {
  width: 100% !important;
}

.sidebar-detached .sidebar-left {
  width: 100% !important;
}

.mt-up {
  margin-top: 2rem !important;
}

@media (max-width: 575.98px) {
  [dir] .dark-layout .card {
    width: 100% !important;
  }
}
</style>
<style scoped>
.faq-area .card-body {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
}
.faq-area .card-body {
  font-size: 16px;
  line-height: normal;
}
.faq-area .card-body p {
  margin-bottom: 30px;
  margin-top: 5px;
}
.faq-area .card-body p span {
  color: var(--primary);
  font-weight: bold;
}
.faq-section .faq-icon {
  height: 50px; /* Adjust the font size to your liking */
  width: 50px;
  display: block;
  margin: 0 auto 30px auto;
  color: var(--primary);
}
.faq-margin {
  margin-top: 0.85rem;
}
.mail-items .mail-message span p {
  margin-bottom: 0 !important;
}
.mail-meta-item .btn {
  font-size: 12px;
  padding: 0.5rem 0.75rem;
}
</style>
