var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{class:[!_vm.message.topic_id ? 'mt-up mb-1 w-md-75' : 'mt-1 mb-1 w-md-75'],style:([
    _vm.message.replier && _vm.message.replier.display_name
      ? 'background-color: rgba(34, 49, 87,0.5); color: #fff; float: left;'
      : '' ]),attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"email-detail-head"},[_c('div',{staticClass:"user-details d-flex justify-content-between align-items-center flex-wrap"},[(!_vm.message.topic_id)?_c('b-avatar',{staticClass:"mr-75",attrs:{"size":"48","src":_vm.message.creator.avatar}}):_c('b-avatar',{staticClass:"mr-75",attrs:{"size":"48","src":_vm.message.replier.avatar}}),_c('div',{staticClass:"mail-items"},[(!_vm.message.topic_id)?_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.message.creator.name)+" ")]):(_vm.message.replier.name)?_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.message.replier.name)+" ")]):_c('h5',{staticClass:"mb-0",staticStyle:{"color":"white"}},[_vm._v(" "+_vm._s(_vm.$t("Pages.Ticket.Title.Mastertube"))+" | "+_vm._s(_vm.message.replier.display_name)+" ")]),(!_vm.message.topic_id)?_c('p',{staticClass:"mb-0 text-muted"},[_vm._v(" "+_vm._s(_vm.$t("Pages.Ticket.Title.User"))+" ")]):(_vm.message.replier.name)?_c('p',{staticClass:"mb-0 text-muted"},[_vm._v(" "+_vm._s(_vm.$t("Pages.Ticket.Title.User"))+" ")]):_c('p',{staticClass:"mb-0 text-muted",staticStyle:{"color":"white"}},[_vm._v(" "+_vm._s(_vm.$t("Pages.Ticket.Title.Admin"))+" ")])])],1),_c('div',{staticClass:"mail-meta-item d-flex align-items-center"},[_c('small',{staticClass:"mail-date-time",staticStyle:{"color":"white"}},[_vm._v(_vm._s(_vm.MyDate.getFormatDateTime(_vm.message.created_at)))])])]),_c('b-card-body',{class:[
      (_vm.message.attachments && _vm.message.attachments.length) ||
      (_vm.message.replier && _vm.message.replier.display_name)
        ? 'mail-message-wrapper pt-2 pb-2'
        : 'mail-message-wrapper pt-2' ]},[_c('p',{staticClass:"mail-message mb-0",domProps:{"innerHTML":_vm._s(_vm.message.content)}})]),(
      (_vm.message.attachments && _vm.message.attachments.length) ||
      (_vm.message.replier && _vm.message.replier.display_name)
    )?_c('b-card-footer',[_c('div',{staticClass:"mail-attachments"},[(_vm.message.replier && _vm.message.replier.display_name)?_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"text-body mb-0"},[_c('p',{staticClass:"font-weight-bolder align-text-top",staticStyle:{"color":"white","font-size":"12px","margin-bottom":"0"}},[_vm._v(" "+_vm._s(_vm.$t("Pages.Ticket.Title.Mastertube"))+" | "+_vm._s(_vm.message.replier.display_name)+" ")]),_c('p',{staticClass:"align-text-top",staticStyle:{"color":"white","font-size":"10px","margin-bottom":"0"}},[_vm._v(" "+_vm._s(_vm.department[ _vm.department.findIndex( function (e) { return e.value == _vm.emailViewData.department; } ) ].name)+" ")])])]):_vm._e(),(_vm.message.attachments.length > 0)?_c('div',{staticClass:"d-flex align-items-center"},[_c('feather-icon',{attrs:{"icon":"PaperclipIcon","size":"16"}}),_c('h5',{staticClass:"font-weight-bolder text-body mb-0 ml-50"},_vm._l((_vm.message.attachments),function(attachment,index){return _c('b-link',{key:index,class:{ 'mt-75': index },attrs:{"href":'https://storage.master.tube/s/' + attachment.url,"target":"_blank"}},[_c('span',{staticClass:"text-muted font-weight-bolder align-text-top"},[_vm._v(_vm._s(attachment.title))])])}),1)],1):_vm._e(),_c('div',{staticClass:"d-flex flex-column"})])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }