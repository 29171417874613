<template>
  <b-card
    no-body
    :class="[!message.topic_id ? 'mt-up mb-1 w-md-75' : 'mt-1 mb-1 w-md-75']"
    :style="[
      message.replier && message.replier.display_name
        ? 'background-color: rgba(34, 49, 87,0.5); color: #fff; float: left;'
        : '',
    ]"
  >
    <b-card-header class="email-detail-head">
      <div
        class="user-details d-flex justify-content-between align-items-center flex-wrap"
      >
        <b-avatar
          size="48"
          v-if="!message.topic_id"
          :src="message.creator.avatar"
          class="mr-75"
        />
        <b-avatar
          size="48"
          v-else
          :src="message.replier.avatar"
          class="mr-75"
        />
        <div class="mail-items">
          <h5 class="mb-0" v-if="!message.topic_id">
            {{ message.creator.name }}
          </h5>
          <h5 class="mb-0" v-else-if="message.replier.name">
            {{ message.replier.name }}
          </h5>
          <h5 class="mb-0" v-else style="color: white">
            {{ $t("Pages.Ticket.Title.Mastertube") }} |
            {{ message.replier.display_name }}
          </h5>
          <p class="mb-0 text-muted" v-if="!message.topic_id">
            {{ $t("Pages.Ticket.Title.User") }}
          </p>
          <p class="mb-0 text-muted" v-else-if="message.replier.name">
            {{ $t("Pages.Ticket.Title.User") }}
          </p>
          <p class="mb-0 text-muted" v-else style="color: white">
            {{ $t("Pages.Ticket.Title.Admin") }}
          </p>
        </div>
      </div>
      <div class="mail-meta-item d-flex align-items-center">
        <small class="mail-date-time" style="color: white">{{
          MyDate.getFormatDateTime(message.created_at)
        }}</small>
        <!-- Mail Action DD -->
      </div>
    </b-card-header>

    <b-card-body
      :class="[
        (message.attachments && message.attachments.length) ||
        (message.replier && message.replier.display_name)
          ? 'mail-message-wrapper pt-2 pb-2'
          : 'mail-message-wrapper pt-2',
      ]"
    >
      <!-- eslint-disable vue/no-v-html -->
      <p class="mail-message mb-0" v-html="message.content" />
      <!-- eslint-enable -->
    </b-card-body>

    <b-card-footer
      v-if="
        (message.attachments && message.attachments.length) ||
        (message.replier && message.replier.display_name)
      "
    >
      <div class="mail-attachments">
        <div
          class="d-flex align-items-center"
          v-if="message.replier && message.replier.display_name"
        >
          <div class="text-body mb-0">
            <p
              class="font-weight-bolder align-text-top"
              style="color: white; font-size: 12px; margin-bottom: 0"
            >
              {{ $t("Pages.Ticket.Title.Mastertube") }} |
              {{ message.replier.display_name }}
            </p>
            <p
              class="align-text-top"
              style="color: white; font-size: 10px; margin-bottom: 0"
            >
              {{
                department[
                  department.findIndex(
                    (e) => e.value == emailViewData.department
                  )
                ].name
              }}
            </p>
          </div>
        </div>
        <div
          class="d-flex align-items-center"
          v-if="message.attachments.length > 0"
        >
          <feather-icon icon="PaperclipIcon" size="16" />
          <h5 class="font-weight-bolder text-body mb-0 ml-50">
            <b-link
              v-for="(attachment, index) in message.attachments"
              :key="index"
              :href="'https://storage.master.tube/s/' + attachment.url"
              target="_blank"
              :class="{ 'mt-75': index }"
            >
              <span class="text-muted font-weight-bolder align-text-top">{{
                attachment.title
              }}</span>
            </b-link>
          </h5>
        </div>

        <div class="d-flex flex-column"></div>
      </div>
    </b-card-footer>
  </b-card>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
  BCard,
  BCardHeader,
  BCardBody,
  BCardFooter,
  BAvatar,
  BLink,
  BImg,
} from "bootstrap-vue";
import { formatDate } from "@core/utils/filter";
import MyDate from "@core/components/date/MyDate.vue";

export default {
  components: {
    BDropdown,
    BDropdownItem,
    BCard,
    BCardHeader,
    BCardBody,
    BCardFooter,
    BAvatar,
    BLink,
    BImg,
  },
  props: {
    message: {
      type: Object,
      required: true,
    },
    department: {
      required: true,
    },
    emailViewData: {
      required: true,
    },
  },
  setup() {
    return {
      MyDate,
      formatDate,
    };
  },
};
</script>

<style scoped>
.w-md-75 {
  width: 75%;
}
@media (max-width: 768px) {
  .w-md-75 {
    width: 100%;
  }
}
</style>
